.react-multi-carousel-list {
	.react-multi-carousel-track {
		margin-bottom: 2.25rem;
	}
}  

.react-multi-carousel-item {

	div {
		display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
	}

}