.grid__galerry {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(2, 1fr);

	& > div {
		border: 1px solid #2c3749;
		border-radius: .15rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media screen and (min-width: 768px) {

		grid-template-columns: repeat(4, 1fr);
	
		& > div {
		
			&:nth-child(3) {
				grid-column: 3 / 5;
			}
			&:nth-child(8) {
				grid-column: 1 / 3;
			}
			&:nth-child(9) {
				grid-column: 3 / 5;
			}
		}
	
	}


}
