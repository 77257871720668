// .react-multi-carousel-list {
// 	.react-multi-carousel-track {
// 		margin-bottom: 2.25rem;
// 	}
// }  

// .react-multi-carousel-item {

// 	div {
// 		display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
// 	}

// }

.carousel-productos {
	padding-bottom: 3rem;
	margin-bottom: 3rem;
	border-bottom: 1px dotted #d8d9dd;

	.react-multi-carousel-track {

		li div {
			margin: 0 .5rem;
			padding: .25rem;
			background: #fff;
			box-shadow: 0 0 0.625rem #d8d9dd;
			border: 1px solid #dedede;
			border-radius: .625rem;
		}
		img {
			max-width: 100%;
		}

	}

	.react-multi-carousel-dot-list {
		bottom: 2rem;
	}


}