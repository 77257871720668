@keyframes motor {
  0%   { top: 4rem; }
  30%   { top: 3.25rem; }
  60%   { top: 4rem; }
  0%   { top: 4rem; }

}

.hero-w {
	position: relative;

	img {
		display: none;
		
		@media screen and (min-width: 767px) {
			display: inline-block;
			position: absolute;
			top: 4rem;
			right: 30%;
			transform: translateX(282px);
			animation-name: motor;
			animation-duration: 4s;
			animation-iteration-count: infinite;
		}	
	}
}
.hero {

	background: #e2e1de url('../../images/home/bg-hero.jpg') center / cover no-repeat;
	clip-path: polygon(0 0, 100% 0, 100% 86%, 0% 100%);
	
	@media screen and (min-width: 767px) {
		clip-path: polygon(0 0, 100% 0, 100% 71%, 0% 100%);
	}
	
	padding: 5rem 0 7rem;
	
	& h1 {
		color: #2c3749;
		font-weight: 700;

		&:after {
			border-bottom: 4px solid #2c3749;
			content: '';
			display: block;
			margin: 1rem 0 2rem;
			max-width: 220px;
		}
	}

	& p {
		font-size: 1.25rem;
		line-height: 1.875rem;
	}

}