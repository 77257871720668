footer {
	background-color: #2c3749;
	color: #fff;
	padding: 2rem 0;

	p, a, a:hover {
		text-align: center;
		color: #fff;
		margin-bottom: 0;
	}

	& .row > div.col-md-4 {
		display: flex;
    justify-content: center;
    align-items: center;
	}

	@media screen and (max-width: 767px) {
		.col-md-4 {
			margin-bottom: 1rem;
		}
	}

	.icon {
		height: 1.25rem;
		margin-right: .4rem;
		margin-top: -.2rem;
	}

}