header {
	background: #fff;

	&:after {
		content: ' ';
		background: #2c3749;
		height: 2px;
		display: block;
	}
}

.navbar-brand > img {
	
	@media screen and (max-width: 767px) {
		max-width: 15rem;
	}
	
}

.navbar-toggler {
	border-color: #2c3749;
	padding: .5rem .75rem;
}


@media screen and (max-width: 767px) {
	.navbar-collapse {
		border-top: 1px dotted #2c3749;
		margin-top: .5rem;
	}
}

.nav-item {
	a {
		cursor: pointer;
		text-align: center;
		font-size: .875rem;
		color: #2c3749;
		letter-spacing: 1px;
	}

	&.active {
		position: relative;
		text-align: center;
	}
}



