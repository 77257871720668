.contact {
	position: relative;
	min-height: 37.875rem;

	.contactForm {
		background: #ffffffc7;
		border-radius: .65rem;
		box-shadow: 0 0 1rem #dedede;
		color: #2c3749;
		left: 50%;
		transform: translateX(-157px);
		padding: 1rem;
		padding-bottom: 0;
		position: absolute;
		width: 22.5rem;
    border: 1px solid #fff;
		top: 5.5rem;
		
		@media screen and (min-width: 768px) {
			left: 12rem;
			transform: translateX(1rem);
		}


		& > h3 {
			text-align: center;
			color: #2c3749;
		}

		label {
			margin-bottom: 0;
			margin-left: .5rem;
			color: #2c3749;
		}

		button {
			width: 100%;
    	margin-bottom: -1rem;
		}
	}

}