// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body, html {
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	@media screen and (min-width: 768px) {
		font-size: 16px;
	}
	
}

h2.title {
	color: #2c3749;
	font-weight: 400;
	text-align: center;

	strong {
		font-weight: 700;
	}

	&:after {
		content: ' ';
		width: 100px;
		border-bottom: 4px solid #2c3749;
		display: block;
		margin: 1rem auto 2rem;
	}
}

.btn-outline-primary {
	color: #2c3749;
	border-color: #2c3749;

	&:hover {
		background-color: #2c3749;
    border-color: #2c3749;
	}
}
.btn-primary {
	background-color: #2c3749;
	border-color: #2c3749;
	transition: background-color .35s ease-in-out;

	&:hover {
		background-color: #6EA2B8;
		border-color: #6EA2B8;
	}
}


// .btn {
// 	border-radius: 1.5rem;
// }



// .btn-default {
// 	background-color: #fff;
// 	border-color: #518ba4;
// 	color: #3C2B7B;
// 	transition: background-color .35s ease-in-out;

// 	&:hover {
// 		background-color: #3C2B7B;
// 		border-color: #3C2B7B;
// 		color: #fff;
// 	}
// }


// .separator {
// 	background: #72ac90;
// 	background: -moz-linear-gradient(left, #72ac90 0%, #4667a5 27%, #653e85 52%, #a9195b 69%, #d08349 87%, #c3b131 100%);
// 	background: -webkit-gradient(left top, right top, color-stop(0%, #72ac90), color-stop(27%, #4667a5), color-stop(52%, #653e85), color-stop(69%, #a9195b), color-stop(87%, #d08349), color-stop(100%, #c3b131));
// 	background: -webkit-linear-gradient(left, #72ac90 0%, #4667a5 27%, #653e85 52%, #a9195b 69%, #d08349 87%, #c3b131 100%);
// 	background: -o-linear-gradient(left, #72ac90 0%, #4667a5 27%, #653e85 52%, #a9195b 69%, #d08349 87%, #c3b131 100%);
// 	background: -ms-linear-gradient(left, #72ac90 0%, #4667a5 27%, #653e85 52%, #a9195b 69%, #d08349 87%, #c3b131 100%);
// 	background: linear-gradient(to right, #72ac90 0%, #4667a5 27%, #653e85 52%, #a9195b 69%, #d08349 87%, #c3b131 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#72ac90', endColorstr='#c3b131', GradientType=1 );
// 	height: 6px;
// 	max-width: 50%;
// 	min-width: 300px;
// 	margin: 1.5rem auto 3rem;
// }